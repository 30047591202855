/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-t-b': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.668 3.684A.48.48 0 018 3.556c.13 0 .239.043.329.128 1.025.976 1.913 1.946 2.888 2.942a.202.202 0 01-.143.343H4.918a.2.2 0 01-.144-.34c.969-1.004 1.871-1.97 2.894-2.945zM7.668 12.316a.48.48 0 00.332.128.459.459 0 00.329-.128c1.025-.976 1.913-1.946 2.888-2.942a.202.202 0 00-.143-.343H4.918a.2.2 0 00-.144.34c.969 1.004 1.871 1.97 2.894 2.945z" _fill="#B0B0B6"/>'
  }
})
